import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We recently conducted a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`survey of Alexa and Google Home`}</a>{` users.`}</p>
    <p>{`One of the interesting topics was around commerce. Are consumers making purchases through their devices? If so, who is, and what are they purchasing?`}</p>
    <h2>{`Consumers are willing to make purchases through their voice devices`}</h2>
    <p>{`Both Alexa and Google enable users to make purchases from their own ecommerce platforms and from other retailers and businesses through account linking. Brands can also monetize their voice apps through subscriptions and “in-app” purchases.`}</p>
    <p>{`Based on our survey, 43% of respondents have made a purchase through their device. In addition, approximately 60% of respondents are likely to make a purchase in the future as well.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073558/voice-shopping-yesno.png",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073625/voice-shopping-likely-all.png",
        "alt": null
      }}></img></p>
    <p>{`Repeat purchasing looks promising too. The consumers who have already made purchases in the past are more likely to make purchases in the future.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073657/voice-shopping-likely-prior.png",
        "alt": null
      }}></img></p>
    <h2>{`What are consumers purchasing?`}</h2>
    <p>{`The most common transaction through a voice device is a purchase through the device’s underlying ecommerce platform – ordering or reordering items. Both Alexa and Google Assistant enable shopping through their ecommerce platforms – Amazon and Google Express, respectively. For example, “Alexa, order me paper towels.”`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073823/voice-shopping-types-prior.png",
        "alt": null
      }}></img></p>
    <h3>{`Ordering food`}</h3>
    <p>{`Ordering food delivery is also a popular transaction on voice devices. Nearly 53% of respondents who made a purchase, ordered food.`}</p>
    <p>{`In our own discussions with restaurants and food delivery services, consumers tend to re-order the same food items each time. This “re-order” case works quite well in voice interfaces as it can be done in shorter, more concise statements, rather than complex menu ordering from scratch.`}</p>
    <h3>{`Other transactions`}</h3>
    <p>{`While less common, consumers do make purchases with other vendors. With account linking, users can more easily complete a transaction with a third party, like ordering a car service. However, improvements within the platforms need to be made to make it easier for vendors to transact – e.g. enabling vendors’ voice apps to add products directly to the underlying shopping carts. Vendors also need to overcome challenges of not having a visual interface when selling some physical products.`}</p>
    <p>{`Subscriptions and in-app purchasing offer promising monetization opportunities for brands and developers. As advertising is currently frowned upon on these devices, brands are looking for other ways to monetize. We’re seeing subscriptions and in-app purchases start to emerge in voice games, similar to what transpired with mobile apps.`}</p>
    <h2>{`Who’s purchasing?`}</h2>
    <p>{`What’s one of the biggest factors in whether consumers have, or are more likely to, make a purchase through their voice device?`}</p>
    <p>{`Whether they have both devices or not.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073817/voice-shopping-likely-device.png",
        "alt": null
      }}></img></p>
    <p>{`It turns out, over 56% of respondents who own both devices have made a purchase in the past, compared to 43% who only have an Alexa and 39% who only have a Google Home. In terms of future purchases, similarly 57% of respondents who own both are “very likely” to make a purchase in the future, compared to 41% of those who only have an Alexa, and 35% who only have a Google Home.`}</p>
    <p>{`It may be that consumers who have both devices tend to be early adopters and are thus more likely to try making a purchase through the device.`}</p>
    <p>{`Similarly, the respondents who felt the device changed their daily behaviors a lot, were more likely to have made a purchase as well – nearly 78.5% of them.`}</p>
    <h3>{`Men vs women`}</h3>
    <p>{`Based on our survey, men are significantly more likely to have made a purchase than women. The percentage of men who have made purchases is nearly double that of women – at 58% and 32% respectively.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/19073819/voice-shopping-likely-gender.png",
        "alt": null
      }}></img></p>
    <p>{`Men are also more likely to make a purchase in the future than women. Approximately 75% of men are at least likely to make a purchase in the future compared to 49% of women.`}</p>
    <p>{`Looking at the earlier data on device ownership, men who own both Alexa and Google home tend to be the most likely to have made a purchase in the past or more likely to in the future.`}</p>
    <h3>{`Is age a factor?`}</h3>
    <p>{`Looking at the age range of respondents, the range with the highest percentage of previous purchases is 35 to 44 at 56%. The two age groups on either side are fairly similar – 50% of ages 25 to 34 and 46% of ages 45 to 54 have also made purchases through their devices.`}</p>
    <h2>{`Conclusions`}</h2>
    <p>{`While the voice space is still new, we are excited by the possibilities of these interfaces. As we have seen in the early days of web and mobile, there is still a lot of progress to be made, but the promise definitely there. Voice is the natural way for humans to interact. Brands and developers are exploring the monetization opportunities and consumers are right there alongside making use of them. We look forward to the continued advancements and what we’ll see in the future.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide conversation specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      